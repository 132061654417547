import React, { useRef, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import styled from "../../theme";
import { Image } from "../../components/UI/Image";
import { Button } from "../../components/UI/Button";
import Timer from "../../components/Timer/Timer";
import { setPhoto } from "../../features/service/serviceSlice";

// Modal settings
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "calc(40px + 4rem + 50px + 430px + 150px)",
  },
};
Modal.setAppElement("#root");

// Webcam settings
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};

const CameraWrapper = styled.div`
  min-width: 100%;
  height: auto;
`;

const ImageBlock = styled(Image)`
  display: block;
`;

interface PhotoModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const PhotoModal = (props: PhotoModalProps) => {
  const { isOpen, closeModal } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);

  // This will be called when the webcam is available
  const onUserMediaHandler = () => {
    setIsTakingPhoto(true);
  };

  const capture = useCallback(() => {
    if (webcamRef && webcamRef.current) {
      // @ts-ignore
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc);
      dispatch(setPhoto(imageSrc));
    }
  }, [webcamRef, dispatch]);

  const onContinueClickHandler = () => {
    // At this point, we can assume that we have a photo! This is checked in renderControls.
    history.push("/success");
  };

  const renderControls = () => {
    if (imageSrc) {
      // If we have a photo, render the "Continue" button and allow the user to go through
      return (
        <Button
          marginTop="2rem"
          marginBottom="2rem"
          onClick={onContinueClickHandler}
        >
          Continuar <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      );
    } else {
      if (isTakingPhoto) {
        // Only start the timer when the webcam is available
        return <Timer onComplete={capture} />;
      }
      // Don't render anything
      return null;
    }
  };

  // If we have a photo, show it. Otherwise, start the webcam.
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Camera Modal"
    >
      {imageSrc ? (
        <ImageBlock src={imageSrc} alt="user" maxWidth="100%" />
      ) : (
        <CameraWrapper>
          <Webcam
            audio={false}
            forceScreenshotSourceSize={true}
            width="100%"
            height="100%"
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            onUserMedia={onUserMediaHandler}
            videoConstraints={videoConstraints}
          />
        </CameraWrapper>
      )}
      {renderControls()}
    </Modal>
  );
};

export default PhotoModal;
