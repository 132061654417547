import React from "react";
import styled from "../../theme";

const Container = styled.h2`
  padding: 0.5rem;
  background-color: rgba(255, 0, 0, 0.3);
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.text};
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
`;

interface ErrorProps {
  message: string;
}

const AppError: React.FC<ErrorProps> = ({ message }) => {
  return <Container>{message}</Container>;
};

export default AppError;
