import * as styledComponents from "styled-components";

const {
  default: styled,
  createGlobalStyle,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<{}>;

const myTheme: styledComponents.DefaultTheme = {
  borderRadius: "5px",
  colors: {
    primary: "#195cad",
    secondary: "magenta",
    background: "#f6f9fc",
    text: "#1c1f2a",
    input: {
      border: "#229dd5",
      placeholder: "#6d7278",
    },
  },
};

export default styled;
export { myTheme, createGlobalStyle };
