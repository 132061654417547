export function objectToQs(obj: Record<string, unknown>) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}

export function formatTime(time = new Date()) {
  return `${time
    .getHours()
    .toString()
    .padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}`;
}

export function isTodayDate(date: Date) {
  const today = new Date();
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
}

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.wegho.com"
    : "http://localhost:8080";

// export const API_URL = "https://api.wegho.com";
