import styled from "../../theme";

export const KeyboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 90px);
  grid-template-rows: repeat(4, 80px);
  grid-gap: 0.4rem;
  margin: 2rem auto 0 auto;
  justify-content: center;
`;

interface KeyboardKeyProps {
  fontSize?: string;
}

export const KeyboardKey = styled.div<KeyboardKeyProps>`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: ${({ fontSize = "2rem" }) => fontSize};
  border: 1px solid white;
  color: white;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
