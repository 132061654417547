import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../components/Logo/Logo";
import {
  Wrapper,
  Content,
  Header,
  SubHeaderText,
} from "../../components/UI/Card";
import {
  InputLabelGroup,
  Label,
  InputWithIcon,
  Select,
} from "../../components/UI/Form";
import welcome from "../../components/UI/welcome.png";
import usernameIcon from "../../components/UI/icon-username.png";
import clockIcon from "../../components/UI/icon-clock.png";
import { Button, ButtonIcon } from "../../components/UI/Button";
import { Help } from "../../components/Help/Help";
import { RootState } from "../../rootReducer";
import {
  fetchServiceData,
  setServiceIndex,
} from "../../features/service/serviceSlice";
import { logout } from "../../features/user/userSlice";
import { formatTime } from "../../utils/misc";

interface ICheckInOutProps {
  isCheckingIn: boolean;
}

export default function CheckInOut(props: ICheckInOutProps) {
  const { isCheckingIn } = props;
  const timeStr = isCheckingIn ? "Hora de Entrada" : "Hora de saída";

  const username = useSelector((state: RootState) => state.user.username);
  const supplierId = useSelector((state: RootState) => state.user.supplierId!);
  const services = useSelector((state: RootState) => state.service.services);
  const serviceFetchError = useSelector(
    (state: RootState) => state.service.error
  );
  const selectedServiceIndex = useSelector(
    (state: RootState) => state.service.selectedServiceIndex
  );
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const onSelectedAddressChange = (e: React.FormEvent<HTMLSelectElement>) => {
    dispatch(setServiceIndex(e.currentTarget.selectedIndex));
  };
  const history = useHistory();
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    history.push("/photo");
  };

  // Redirect home if inactive for 60s
  useEffect(() => {
    const timer = setTimeout(() => dispatch(logout()), 60 * 1000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    // fetch service data form the api
    dispatch(fetchServiceData(supplierId));
  }, [dispatch, supplierId]);

  useEffect(() => {
    // The "selectedServiceIndex" variable is null until services data is fetched, either from the api or LS
    if (
      !serviceFetchError &&
      selectedServiceIndex !== null &&
      services.length > 0
    ) {
      setTime(formatTime());
      setLoading(false);
    } else if (serviceFetchError) {
      setLoading(false);
    }
  }, [isCheckingIn, services, selectedServiceIndex, serviceFetchError]);

  const getAllServices = () => {
    // @ts-ignore
    return services.map((s, i) => (
      <option value={s.streetAddress!} key={i}>
        {`${s.streetAddress} - Turno ${s.schedulingHour}`}
      </option>
    ));
  };

  const getCurrentService = () => {
    const currentService = services.filter((s) => s.checkInDate);
    if (currentService.length > 0) {
      const cs = currentService[0];
      return (
        <option value={cs.streetAddress!} key={cs.streetAddress!}>
          {cs.streetAddress}
        </option>
      );
    }
  };

  const checkInForm = () => {
    return (
      <>
        <InputLabelGroup width={60}>
          <Label htmlFor="username">Prestador</Label>
          <InputWithIcon
            id="username"
            icon={usernameIcon}
            type="text"
            readOnly
            value={username || ""}
          />
        </InputLabelGroup>
        <InputLabelGroup width={30}>
          <Label htmlFor="time">{timeStr}</Label>
          <InputWithIcon
            id="time"
            icon={clockIcon}
            type="text"
            readOnly
            value={time}
          />
        </InputLabelGroup>
        <Select
          name="serviceAddresses"
          id="serviceAddresses"
          onChange={onSelectedAddressChange}
        >
          {isCheckingIn ? getAllServices() : getCurrentService()}
        </Select>
        <Button marginTop="2rem" marginBottom="2rem" onClick={onClickHandler}>
          Continuar <ButtonIcon side="right" icon={faArrowRight} />
        </Button>
      </>
    );
  };

  return (
    <>
      <Logo />
      <Wrapper>
        <Header>
          <img src={welcome} alt="welcome img" width="150px" height="150px" />
          <SubHeaderText>Bem-vinda/o!</SubHeaderText>
        </Header>
        <Content>
          {loading ? "A carregar dados..." : serviceFetchError ?? checkInForm()}
        </Content>
      </Wrapper>
      <Help />
    </>
  );
}
