import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user/userSlice";
import usernameIcon from "../UI/icon-username.png";
import passwordIcon from "../UI/icon-password.png";
import {
  Wrapper,
  Content,
  Header,
  Footer,
  FooterText,
  HeaderText,
  SubHeaderText,
} from "../UI/Card";
import { InputLabelGroup, Label, InputWithIcon } from "../UI/Form";
import { Link } from "../UI/Link";
import AppError from "../UI/Error";
import { RootState } from "../../rootReducer";
import ScreenKeyboard from "../ScreenKeyboard/ScreenKeyboard";

export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [usernameFocus, setUsernameFocus] = useState(true);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const loginErrorMsg = useSelector((state: RootState) => state.user.error);

  const resetLogin = () => {
    console.log("Reseting login form");
    setUsername("");
    setPassword("");
    setUsernameFocus(true);
    usernameInputRef.current?.focus();
    setPasswordFocus(false);
    passwordInputRef.current?.blur();
  };

  const handleKeyboardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { val } = e.currentTarget.dataset;
    const num = Number.parseInt(val as string, 10);
    if (num === -2) {
      // -1 -> CLEAR ALL
      resetLogin();
    } else if (usernameFocus) {
      if (num === -1) {
        // -1 -> DELETE LAST CHAR
        setUsername((prev) => prev.slice(0, -1));
      } else {
        // username WAS 3 chars, and user added 1 more -> move to password
        if (username.length === 3) {
          usernameInputRef.current?.blur();
          setUsernameFocus(false);
          passwordInputRef.current?.focus();
          setPasswordFocus(true);
        }
        setUsername((prev) => `${prev}${num}`);
      }
    } else if (passwordFocus) {
      if (num === -1) {
        // -1 -> DELETE LAST CHAR
        if (password.length === 0) {
          usernameInputRef.current?.focus();
          setUsernameFocus(true);
          passwordInputRef.current?.blur();
          setPasswordFocus(false);
          setUsername((prev) => prev.slice(0, -1));
        }
        setPassword((prev) => prev.slice(0, -1));
      } else {
        // password WAS 3 chars, and user added 1 more -> lose focus and login
        if (password.length === 3) {
          passwordInputRef.current?.blur();
          setPasswordFocus(false);
        }
        setPassword((prev) => `${prev}${num}`);
      }
    }
  };

  // Reset form if login fails
  useEffect(() => {
    if (loginErrorMsg) {
      resetLogin();
    }
  }, [loginErrorMsg]);

  // Login when both inputs are filled
  useEffect(() => {
    if (username.length === 4 && password.length === 4) {
      dispatch(login(username, password));
    }
  }, [dispatch, username, password]);

  // Reset after 30s of inactivity
  // Inactivity is when username or password are not changed for a while
  // A change in either will trigger the useEffect and restart the timer
  useEffect(() => {
    if (username.length > 0 || password.length > 0) {
      const timer = setTimeout(() => resetLogin(), 30 * 1000);
      return () => clearTimeout(timer);
    }
  }, [username, password]);

  return (
    <>
      <Wrapper>
        <Header>
          <HeaderText>Olá!</HeaderText>
          <SubHeaderText>Vamos começar?</SubHeaderText>
        </Header>
        <Content>
          {loginErrorMsg && <AppError message={loginErrorMsg} />}
          <InputLabelGroup>
            <Label htmlFor="username">Código Colaborador</Label>
            <InputWithIcon
              icon={usernameIcon}
              id="username"
              type="text"
              readOnly
              placeholder="Insira aqui o seu código pessoal"
              autoFocus
              value={username}
              ref={usernameInputRef}
              shouldFocus={usernameFocus && username.length < 4}
            ></InputWithIcon>
          </InputLabelGroup>
          <InputLabelGroup>
            <Label htmlFor="password">Pin Secreto</Label>
            <InputWithIcon
              icon={passwordIcon}
              id="password"
              type="text"
              readOnly
              placeholder="Insira aqui o seu pin secreto"
              value={password}
              ref={passwordInputRef}
              shouldFocus={passwordFocus && password.length < 4}
            ></InputWithIcon>
          </InputLabelGroup>
        </Content>
        <Footer>
          <FooterText>
            Esqueceu-se do seu pin? <Link>Clique aqui.</Link>
          </FooterText>
        </Footer>
      </Wrapper>
      <ScreenKeyboard onClickHandler={handleKeyboardClick} />
    </>
  );
}
