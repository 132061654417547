import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import styledNormalize from "styled-normalize";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import App from "./App";
import { Router } from "react-router-dom";
import { myTheme } from "./theme";
import history from "./utils/history";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn:
    "https://bb32a5b3fa5c42a0b5eba57b561f1748@o391117.ingest.sentry.io/5238024",
});

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    background: linear-gradient(to bottom,#185a9d,#43cea2) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    padding-top: 3rem;
    position: relative;
  }
  #root {
    height: 100%;
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={myTheme}>
      <GlobalStyle />
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
