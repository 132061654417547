import {
  createSlice,
  PayloadAction,
  createAction,
  Action,
} from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import {
  updateServiceDataLS,
  getUserDatafromLS,
} from "../../utils/localstorage";
import { objectToQs, API_URL } from "../../utils/misc";

interface ServiceState {
  selectedServiceIndex: number | null;
  services: {
    monthlyAccrualServicePlanId: number | null;
    bookingId: number | null;
    addressId: number;
    streetAddress: string;
    schedulingHour: string;
    photo?: string;
    actionId?: number;
    latitude?: number;
    longitude?: number;
    checkInDate?: string;
    checkOutDate?: string;
  }[];
  error: string | null;
}

const initialState: ServiceState = {
  selectedServiceIndex: null,
  services: [],
  error: null,
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServiceData: (
      state,
      action: PayloadAction<ServiceState["services"]>
    ) => {
      if (
        state.services.length > 0 &&
        action.payload.length === 1 &&
        action.payload[0].checkInDate
      ) {
        state.services = state.services.map((s) => {
          if (
            s.bookingId === action.payload[0].bookingId ||
            s.monthlyAccrualServicePlanId ===
              action.payload[0].monthlyAccrualServicePlanId
          ) {
            s.checkInDate = action.payload[0].checkInDate;
          }
          return s;
        });
      } else {
        state.services = action.payload;
      }
      // Set first service as selected (default)
      state.selectedServiceIndex = 0;
      state.error = null;
    },
    fetchServiceDataError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setPhoto: (state, action: PayloadAction<string>) => {
      state.services[state.selectedServiceIndex!].photo = action.payload;
    },
    createEventSuccess: (
      state,
      action: PayloadAction<{ type: string; supplierId: string }>
    ) => {
      console.log("CREATE EVENT SUCCESS");
      // state.error = null;
      // state.selectedServiceIndex = null;
      // // @ts-ignore
      // state.services = state.services.map((s) => {
      //   s.photo = null;
      //   // @ts-ignore
      //   if (action.meta.type === "checkin") {
      //     s.checkInDate = new Date().toString();
      //     s.checkOutDate = null;
      //     // @ts-ignore
      //   } else if (action.meta.type === "checkout") {
      //     s.checkInDate = null;
      //     s.checkOutDate = new Date().toString();
      //   }
      //   return s;
      // });
      // updateServiceDataLS({
      //   // @ts-ignore
      //   supplierId: action.meta.supplierId,
      //   services: state.services as any,
      // });
    },
    createEventFail: (
      state,
      action: PayloadAction<{ type: string; supplierId: string }>
    ) => {
      // This doesn't make sense. If this gets hit immediatly when offline, the event won't be sent to the api.
      // Otherwise, if we wait for a connection, the checkin/out status won't be updated properly and won't match
      // the actual status, invalidating all future events
      // console.log("CREATE EVENT FAIL");
      // state.error = "Offline";
      // state.selectedServiceIndex = null;
      // // @ts-ignore
      // state.services = state.services.map((s) => {
      //   s.photo = null;
      //   // @ts-ignore
      //   if (action.meta.type === "checkin") {
      //     s.checkInDate = new Date().toString();
      //     s.checkOutDate = null;
      //     // @ts-ignore
      //   } else if (action.meta.type === "checkout") {
      //     s.checkInDate = null;
      //     s.checkOutDate = new Date().toString();
      //   }
      //   return s;
      // });
      // updateServiceDataLS({
      //   // @ts-ignore
      //   supplierId: action.meta.supplierId,
      //   services: state.services as any,
      // });
    },
    updateServiceStatus: (
      state,
      action: PayloadAction<{ type: string; supplierId: string }>
    ) => {
      console.log("updateServiceStatus - action type:", action.payload.type);
      console.log(
        "updateServiceStatus - supplierId",
        action.payload.supplierId
      );
      const services = [...state.services];
      const selectedServiceIndex = state.selectedServiceIndex;
      if (selectedServiceIndex === null) {
        console.error("No selected service index.");
        return;
      }
      const currentService = { ...services[selectedServiceIndex] };
      delete currentService.photo;
      delete currentService.actionId;
      delete currentService.latitude;
      delete currentService.longitude;
      if (action.payload.type === "checkin") {
        currentService.checkInDate = new Date().toString();
        delete currentService.checkOutDate;
      } else if (action.payload.type === "checkout") {
        delete currentService.checkInDate;
        currentService.checkOutDate = new Date().toString();
      }
      services[selectedServiceIndex] = currentService;
      // TODO: We actually only care about the single service being updated, not all services in state
      updateServiceDataLS(
        {
          supplierId: action.payload.supplierId,
          services,
        },
        true
      );
      state.services = [];
      state.error = null;
      state.selectedServiceIndex = null;
    },
    setServiceIndex: (state, action: PayloadAction<number>) => {
      console.log("setServiceIndex", action.payload);
      state.selectedServiceIndex = action.payload;
    },
    getServicesFromLS: (state, action: PayloadAction<string>) => {
      console.log("getServicesFromLS", action.payload);
      const userData = getUserDatafromLS(action.payload);
      state.services = userData.services;
    },
    setServices: (state, action: PayloadAction<any>) => {
      if (action.payload?.length > 0) {
        state.selectedServiceIndex = 0;
      }
      state.services = action.payload;
    },
    clearServices: (state, action: Action) => {
      state.services = [];
      state.error = null;
      state.selectedServiceIndex = null;
    },
  },
});

export const {
  setServiceData,
  fetchServiceDataError,
  setPhoto,
  setServiceIndex,
  getServicesFromLS,
  setServices,
  updateServiceStatus,
  clearServices,
} = serviceSlice.actions;

export default serviceSlice.reducer;

interface GetServiceData {
  data:
    | {
        schedulingHour: string;
        streetAddress: string;
        addressId: number;
        monthlyAccrualServicePlanId: number | null;
        bookingId: number | null;
      }[]
    | [];
}

export const fetchServiceData = (supplierId: string): AppThunk => async (
  dispatch
) => {
  try {
    const params = {
      supplierId,
    };
    const res = await fetch(
      `${API_URL}/v1/supplier/service?${objectToQs(params)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          appOrigin: "Timeclock",
        },
        credentials: "include",
      }
    );
    if (!res.ok) throw new Error("Server Error");
    const serviceData: GetServiceData = await res.json();
    // Check if we get any services
    if (serviceData.data.length === 0) {
      dispatch(fetchServiceDataError("Não tem serviços atribuidos."));
    } else {
      dispatch(setServiceData(serviceData.data));
      updateServiceDataLS({ supplierId, services: serviceData.data });
    }
  } catch (err) {
    console.error("Error in fetchServiceData", err.toString());
    dispatch(
      fetchServiceDataError(
        "Erro a obter dados do servidor. Por favor, tente mais tarde."
      )
    );
  }
};

export const createEvent = createAction("service/createEvent", function prepare(
  supplierId,
  monthlyAccrualServicePlanId,
  bookingId,
  addressId,
  photo,
  isWorking,
  latitude,
  longitude
) {
  return {
    payload: {
      supplierId,
      monthlyAccrualServicePlanId,
      bookingId,
      addressId,
      photo,
      isWorking,
      latitude,
      longitude,
    },
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${API_URL}/v1/supplier/service/checkinout`,
          method: "POST",
          credentials: "include",
          json: {
            supplierId,
            monthlyAccrualServicePlanId,
            bookingId,
            addressId,
            photo,
            isWorking,
            latitude,
            longitude,
            createdAt: new Date().toString(),
          },
        },
        // action to dispatch when effect succeeds:
        // commit: {
        //   type: "service/createEventSuccess",
        //   meta: { type: isWorking ? "checkout" : "checkin", supplierId },
        // },
        // action to dispatch if network action fails permanently:
        // rollback: {
        //   type: "service/createEventFail",
        //   meta: { type: isWorking ? "checkout" : "checkin", supplierId },
        // },
      },
    },
  };
});
