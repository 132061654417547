import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styled, { myTheme } from "../../theme";

const CountdownWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
`;

interface ITimerProps {
  onComplete: () => void;
}

export default function Timer(props: ITimerProps) {
  return (
    <CountdownWrapper>
      <CountdownCircleTimer
        isPlaying
        duration={5}
        onComplete={props.onComplete}
        size={50}
        strokeWidth={3}
        // @ts-ignore
        colors={[[myTheme.colors.primary]]}
        trailColor={"#ffffff"}
      >
        {({ remainingTime }: any) => remainingTime}
      </CountdownCircleTimer>
    </CountdownWrapper>
  );
}
