import React from "react";
import styled from "styled-components";
import logo from "./logo-white.png";

const LogoImg = styled.img.attrs({
  src: logo,
  alt: "Wegho",
})`
  max-width: 250px;
  display: block;
`;

const LogoContainer = styled.div`
  margin: 0 auto 2rem auto;
  width: 250px;
`;

const Version = styled.h5`
  text-align: right;
  color: white;
  margin: 0;
`;

const Logo: React.FC = () => {
  return (
    <LogoContainer>
      <LogoImg />
      <Version>v{process.env.REACT_APP_VERSION}</Version>
    </LogoContainer>
  );
};

export default Logo;
