import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { setServices } from "../service/serviceSlice";
import history from "../../utils/history";
import {
  getTokenFromUserDataLS,
  createOrUpdateUserDataLS,
  getUserDatafromLS,
} from "../../utils/localstorage";
import verifyJwtToken from "../../utils/auth";
import { API_URL, isTodayDate } from "../../utils/misc";

interface LoginState {
  isLoggedIn: boolean;
  error: string | null;
  username: string | null;
  userId: string | null;
  supplierId: string | null;
  isWorking: boolean | null;
}

const initialState: LoginState = {
  isLoggedIn: false,
  error: null,
  username: "",
  isWorking: false,
  userId: null,
  supplierId: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<Record<string, any>>) => {
      state.isLoggedIn = true;
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.supplierId = action.payload.supplierId;
      state.isWorking = action.payload.isWorking;
      state.error = null;
    },
    loginError: (state, action: PayloadAction<string>) => {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.username = null;
      state.userId = null;
      state.supplierId = null;
      state.error = null;
      state.isWorking = null;
    },
  },
});

export const isLoggedIn = (state: LoginState) => state.isLoggedIn;
export const loginErrorMsg = (state: LoginState) => state.error;
export const { loginSuccess, loginError, logout } = loginSlice.actions;

export default loginSlice.reducer;

export const login = (username: string, password: string): AppThunk => async (
  dispatch
) => {
  try {
    const res = await fetch(`${API_URL}/v1/auth/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        appOrigin: "Timeclock",
      },
      credentials: "include",
      body: JSON.stringify({ codeUsername: username, codePassword: password }),
    });
    if (res.ok) {
      // status 2XX
      const {
        userId,
        supplierId,
        userName: username,
        isCurrentlyWorking: isWorking,
        token,
      } = await res.json();
      dispatch(
        loginSuccess({
          userId,
          supplierId,
          username,
          isWorking,
        })
      );
      // Save login data to localstorage
      createOrUpdateUserDataLS({
        codeUsername: username,
        token,
        supplierId,
        username,
        userId,
      });
      // Redirect to checkin/checkout depending on whether the user is currently working or not
      if (isWorking) {
        history.push("/checkout");
      } else {
        history.push("/checkin");
      }
    } else {
      // Note that this is not a network error
      if (res.status >= 400 && res.status < 500) {
        // Authentication error (wrong credentials)
        dispatch(loginError("Credenciais erradas."));
      } else {
        // Server error
        dispatch(loginError("Erro do servidor. Tente mais tarde."));
      }
    }
  } catch (err) {
    // Note that fetch() will only reject a promise if the user is offline, or some unlikely networking error occurs,
    // such a DNS lookup failure
    // Check if the user has already logged in before and the current token is valid. Otherwise, we don't allow the
    // user to go through
    console.log("login offline", err);
    // debugger;
    const userAuthToken = getTokenFromUserDataLS(username);
    console.log("userAuthToken", userAuthToken);
    if (userAuthToken) {
      try {
        verifyJwtToken(userAuthToken);
        console.log("verified token");
      } catch (error) {
        dispatch(loginError("Invalid token"));
      }
      // Get userData from LS
      const userData = getUserDatafromLS(username);
      console.log("got user data", userData);
      // Add services to state (from LS)
      dispatch(setServices(userData.services));
      console.log("set services");
      // Check if any service has checkin/checkout, so we know where to redirect to
      // Check if checkin or checkout. Unlike the online login, where we have access to isCurrentlyWorking, in the offline
      // login, we have to get the services from the LS to figure out the action
      // If a service has checkOutDate OR the last checking was not today OR doesn't have either (new service) -> checkin
      // Else -> checkout
      const isCheckingOut =
        userData.services?.filter(
          (s: any) => s.checkInDate && isTodayDate(new Date(s.checkInDate))
        ).length > 0;
      console.log("isCheckingOut", isCheckingOut);
      const isCheckingIn =
        userData.services?.filter(
          (s: any) =>
            s.checkOutDate ||
            (s.checkInDate && !isTodayDate(new Date(s.checkInDate))) ||
            (!s.checkOutDate && !s.checkInDate)
        ).length > 0;
      console.log("isCheckingIn", isCheckingIn);
      // Force login
      dispatch(
        loginSuccess({
          userId: userData.userId,
          supplierId: userData.supplierId,
          username: userData.username,
          isWorking: isCheckingOut ?? false,
        })
      );
      // Redirect according
      if (isCheckingOut) {
        history.push("/checkout");
      } else {
        history.push("/checkin");
      }
    } else {
      dispatch(
        loginError("Tem de fazer login quando a app voltar a ficar online!")
      );
    }
  }
};
