import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "./rootReducer";
import { Route, useLocation, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import CheckInOut from "./pages/CheckinOut/CheckinOut";
import Photo from "./pages/Photo/Photo";
import Success from "./pages/Success/Success";
import SupportModal from "./pages/Support/Support";
import SupportSuccess from "./pages/Support/Success/Success";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface PrivateRouteProps {
  component?: React.ElementType | null;
  render?: any;
  path: string;
}
function PrivateRoute({
  component: Component = null,
  render,
  path,
}: PrivateRouteProps) {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  return (
    <Route
      path={path}
      render={(props) =>
        isLoggedIn ? (
          render ? (
            render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  let location = useLocation();
  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.
  // @ts-ignore
  let background = location.state && location.state.background;
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute
          path="/checkin"
          render={(props: any) => <CheckInOut {...props} isCheckingIn={true} />}
        />
        <PrivateRoute
          path="/checkout"
          render={(props: any) => (
            <CheckInOut {...props} isCheckingIn={false} />
          )}
        />
        <PrivateRoute path="/photo" component={Photo} />
        <PrivateRoute path="/success" component={Success} />
        <PrivateRoute path="/support/success" component={SupportSuccess} />
        <Route path="*" component={Login} />
      </Switch>
      {/* Show the modal when a background page is set */}
      {background && <Route path="/support" children={<SupportModal />} />}
    </div>
  );
}

export default App;
