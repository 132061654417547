/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../components/Logo/Logo";
import {
  Wrapper,
  Content,
  Header,
  SubHeaderText,
} from "../../components/UI/Card";
import {
  InputLabelGroup,
  Label,
  InputWithIcon,
} from "../../components/UI/Form";
import welcome from "../../components/UI/welcome.png";
import usernameIcon from "../../components/UI/icon-username.png";
import { Button, ButtonIcon } from "../../components/UI/Button";
import { Help } from "../../components/Help/Help";
import { RootState } from "../../rootReducer";
import PhotoModal from "./PhotoModal";
import { logout } from "../../features/user/userSlice";

export default function Photo() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const username = useSelector((state: RootState) => state.user.username);
  const dispatch = useDispatch();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onConfirmIdentityClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsModalOpen(true);
  };

  // Redirect home if inactive for 60s
  useEffect(() => {
    const timer = setTimeout(() => dispatch(logout()), 60 * 1000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <>
      <Logo />
      <Wrapper>
        <Header>
          <img src={welcome} alt="welcome img" width="150px" height="150px" />
          <SubHeaderText>
            Está quase... necessitamos de confirmar a sua identidade.
          </SubHeaderText>
        </Header>
        <Content>
          <InputLabelGroup width={100}>
            <Label htmlFor="username">Prestador</Label>
            <InputWithIcon
              icon={usernameIcon}
              id="username"
              type="text"
              readOnly
              value={username || ""}
            />
          </InputLabelGroup>
          <Button
            marginTop="2rem"
            marginBottom="2rem"
            onClick={onConfirmIdentityClick}
          >
            <ButtonIcon side="left" icon={faCamera} size="lg" />
            Tirar fotografia
          </Button>
        </Content>
      </Wrapper>
      <PhotoModal closeModal={closeModal} isOpen={isModalOpen} />
      <Help />
    </>
  );
}
