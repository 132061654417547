import React from "react";
import Logo from "../../../components/Logo/Logo";
import { Wrapper, SubHeaderText } from "../../../components/UI/Card";
import headerImg from "./cleaningppl.png";
import checkIcon from "./check-icon.png";
import styled from "../../../theme";
import { Image } from "../../../components/UI/Image";

const Title = styled(SubHeaderText)`
  color: #078534;
  flex-basis: 100%;
  text-align: center;
  font-weight: bold;
`;

interface IImageContainer {
  marginTop?: string;
  marginBottom?: string;
}

const ImageContainer = styled.div<IImageContainer>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  width: 100%;
`;

const Text = styled(SubHeaderText)`
  margin-top: 1rem;
`;

export default function SupportSuccess() {
  return (
    <>
      <Logo />
      <Wrapper>
        <ImageContainer>
          <Image src={headerImg} alt="check icon" maxWidth="40%" />
        </ImageContainer>
        <ImageContainer marginTop="2rem">
          <Image src={checkIcon} alt="cleaning" />
        </ImageContainer>
        <Title>O seu pedido foi enviado!</Title>
        <Text>Brevemente entramos em contacto.</Text>
      </Wrapper>
    </>
  );
}
