import React, { useEffect } from "react";
import Logo from "../../components/Logo/Logo";
import LoginForm from "../../components/LoginForm/LoginForm";
import { useDispatch } from "react-redux";
import { clearServices } from "../../features/service/serviceSlice";

export default function Login() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearServices());
  }, [dispatch]);
  return (
    <>
      <Logo />
      <LoginForm />
    </>
  );
}
