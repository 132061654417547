import styled from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IButtonProps {
  marginTop?: string;
  marginBottom?: string;
}

export const Button = styled.button<IButtonProps>`
  border: none;
  border-radius: 6px;
  background-image: linear-gradient(to bottom, #3695d6, #195cad);
  text-transform: uppercase;
  color: white;
  width: 100%;
  padding: 20px 0;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
  cursor: pointer;
`;

interface IButtonIconProps {
  side: string;
}

export const ButtonIcon = styled(FontAwesomeIcon)<IButtonIconProps>`
  margin-right: ${(props) => (props.side === "left" ? "1rem" : 0)};
  margin-left: ${(props) => (props.side === "right" ? "1rem" : 0)};
`;
