import styled from "../../theme";

interface IImageProps {
  width?: string;
  maxWidth?: string;
}

export const Image = styled.img<IImageProps>`
  max-width: ${props => props.maxWidth};
  width: ${props => props.width};
  height: auto;
`;
