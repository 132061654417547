import React, { useEffect, useRef } from "react";
import { useWindowSize, useGeolocation } from "react-use";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import styled from "../../theme";
import headerImg from "./cleaningppl.png";
import checkIcon from "./check-icon.png";
import Logo from "../../components/Logo/Logo";
import { Wrapper, SubHeaderText } from "../../components/UI/Card";
import { Image } from "../../components/UI/Image";
import {
  createEvent,
  updateServiceStatus,
} from "../../features/service/serviceSlice";
import { logout } from "../../features/user/userSlice";
import { RootState } from "../../rootReducer";

const Title = styled(SubHeaderText)`
  color: #078534;
  flex-basis: 100%;
  text-align: center;
  font-weight: bold;
`;

interface IImageContainer {
  marginTop?: string;
  marginBottom?: string;
}

const ImageContainer = styled.div<IImageContainer>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop ?? 0};
`;

const Text = styled(SubHeaderText)`
  margin-top: 1rem;
`;

export default function Success() {
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const service = useSelector((state: RootState) => state.service);
  const hasSent = useRef(false);
  const location = useGeolocation({
    enableHighAccuracy: false,
    timeout: 1 * 60 * 1000,
    maximumAge: 0,
  });

  useEffect(() => {
    if (!location.loading && !hasSent.current) {
      hasSent.current = true;
      // This will send the create event request to the API. We ignore the result of this call,
      // and update the service status regardless,
      // TODO: Investigate implications of this assumption (ie: api rejects the event when device goes back online
      // (and there were multiple events generated after that) -> mismatch between actions -> all requests will fail)
      // UPDATE: The mismatch may not be an actual mismatch, but the db records will have a skipped action.
      // debugger;
      dispatch(
        createEvent(
          user.supplierId,
          // TODO: This can be moved to the reducer
          service.services[service.selectedServiceIndex!]
            .monthlyAccrualServicePlanId,
          service.services[service.selectedServiceIndex!].bookingId,
          service.services[service.selectedServiceIndex!].addressId,
          service.services[service.selectedServiceIndex!].photo,
          user.isWorking,
          location.latitude ?? 0,
          location.longitude ?? 0
        )
      );
      dispatch(
        updateServiceStatus({
          type: user.isWorking ? "checkout" : "checkin",
          supplierId: user.supplierId!,
        })
      );
      setTimeout(() => dispatch(logout()), 60 * 1000);
    }
  }, [
    dispatch,
    user,
    service,
    location.loading,
    location.latitude,
    location.longitude,
  ]);

  return (
    <>
      {user.isWorking && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={100}
          tweenDuration={3000}
        />
      )}
      <Logo />
      <Wrapper>
        <ImageContainer>
          <Image src={headerImg} alt="check icon" maxWidth="60%" />
        </ImageContainer>
        <ImageContainer marginTop="2rem">
          <Image src={checkIcon} alt="cleaning" />
        </ImageContainer>
        <Title>
          {user.isWorking ? "Saída confirmada!" : "Entrada confirmada!"}
        </Title>
        <Text>
          Bom Trabalho{" "}
          <span role="img" aria-label="emoji">
            💪🏻
          </span>
        </Text>
      </Wrapper>
    </>
  );
}
