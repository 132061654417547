interface authData {
  codeUsername: string;
  supplierId: string;
  userId: string;
  username: string;
  token: string;
}

interface serviceData {
  supplierId: string;
  services: {
    schedulingHour: string;
    streetAddress: string;
    addressId: number;
    monthlyAccrualServicePlanId: number | null;
    bookingId: number | null;
    checkInDate?: string;
    checkOutDate?: string;
  }[];
}

interface LSData extends Array<authData | (authData & serviceData)> {}

const LS_KEYNAME = "weghotimeclockdata";

export function createOrUpdateUserDataLS(data: authData): void {
  console.log("createOrUpdateUserDataLS - START", data);
  let dataToSave: LSData = [];
  try {
    const userData = localStorage.getItem(LS_KEYNAME);
    if (!userData) {
      // No data in LS yet
      dataToSave = [data];
    } else {
      dataToSave = JSON.parse(userData);
      const userIndex = dataToSave.findIndex(
        (u) => u.supplierId === data.supplierId
      );
      if (userIndex === -1) {
        // New user in LS
        dataToSave.push(data);
      } else {
        // Existing user in LS. May have services.
        dataToSave[userIndex] = {
          ...dataToSave[userIndex],
          ...data,
        };
      }
    }
    console.log("createOrUpdateUserDataLS - END", dataToSave);
    localStorage.setItem(LS_KEYNAME, JSON.stringify(dataToSave));
  } catch (error) {
    console.error(
      "createOrUpdateUserDataLS Error getting/setting data in LS",
      error
    );
  }
}

export function updateServiceDataLS(
  data: serviceData,
  isSuccess = false
): void {
  console.log("updateServiceDataLS - START", data);
  // debugger;
  let dataToSave: LSData = [];
  try {
    const userData = localStorage.getItem(LS_KEYNAME);
    console.log("userDate", userData);
    // redundant check. ls will always be populated on login (before this)
    if (userData) {
      dataToSave = JSON.parse(userData);
      console.log("dataToSave", dataToSave);
      // redundant check. ls will always be populated on login (before this)
      if (dataToSave) {
        const userIndex = dataToSave.findIndex(
          (u) => u.supplierId === data.supplierId
        );
        console.log("userIndex", userIndex);
        // redundant check. user will be fetched on login (before this)
        if (userIndex !== -1) {
          // Check if we are in the success page
          if (isSuccess) {
            // Here we update the service which the user is checking in/out (replace all data)
            // Replace all data regarding the service being updated
            const serviceIndex = (dataToSave[
              userIndex
            ] as serviceData).services.findIndex(
              (s: serviceData["services"][0]) =>
                s.bookingId === data.services[0].bookingId ||
                s.monthlyAccrualServicePlanId ===
                  data.services[0].monthlyAccrualServicePlanId
            );
            console.log("in success page, updating only 1 service");
            (dataToSave[userIndex] as serviceData).services[serviceIndex] =
              data.services[0];
          } else {
            // Atm, this will get it when fetching services after login
            // Check if we are getting a checkout service from the api OR a list of services
            // This depends on whether the user is currently working or not
            const isCheckout =
              data.services.length === 1 &&
              data.services[0].hasOwnProperty("checkInDate");
            console.log("ischeckout", isCheckout);
            if (
              isCheckout &&
              (dataToSave[userIndex] as serviceData).services?.length > 0
            ) {
              // Update service data (this service is supposed to already exist in LS)
              // The only scenario where this does not exist, is when the LS has no data on the service
              // is: the user logs in to checkout, without data from the previous checkin
              // in this case, we don't care and throw the service data
              const serviceIndex = (dataToSave[
                userIndex
              ] as serviceData).services.findIndex(
                (s: serviceData["services"][0]) =>
                  s.bookingId === data.services[0].bookingId ||
                  s.monthlyAccrualServicePlanId ===
                    data.services[0].monthlyAccrualServicePlanId
              );
              console.log("service with checkin found at index", serviceIndex);
              (dataToSave[userIndex] as serviceData).services[serviceIndex] =
                data.services[0];
              console.log(
                "services1",
                (dataToSave[userIndex] as serviceData).services
              );
            } else {
              // Overwrite all services (this may have more, less or the same services as we already had - but we don't care)
              (dataToSave[userIndex] as serviceData).services = data.services;
              console.log(
                "services3",
                (dataToSave[userIndex] as serviceData).services
              );
            }
          }
        }
      }
    }
    console.log("updateServiceDataLS - END", dataToSave);
    localStorage.setItem(LS_KEYNAME, JSON.stringify(dataToSave));
  } catch (error) {
    console.error(
      "updateServiceDataLS Error getting/setting data in LS",
      error
    );
  }
}

export function getUserDatafromLS(codeUsername: string) {
  console.log("getUserDatafromLS - START", codeUsername);
  try {
    const rawData = localStorage.getItem(LS_KEYNAME);
    if (!rawData) {
      return null;
    }
    const data = JSON.parse(rawData);
    const userData = data.filter((d: any) => d.codeUsername === codeUsername);
    if (!userData) {
      return null;
    }
    console.log("getUserDatafromLS - END", userData[0]);
    return userData[0];
    // .flatMap((d: any) => d.services);
  } catch (error) {
    console.error("Error getting data from LS", error);
    return null;
  }
}

// This may be auth or service data
// export function updateUserDataLS(newData: authData | serviceData): void {
//   debugger;
//   // Get current data
//   let suppliersData: LSData | null = null;
//   try {
//     // Get auth data from LS
//     const suppliersDataUnparsed = localStorage.getItem(LS_KEYNAME);
//     if (suppliersDataUnparsed) {
//       suppliersData = JSON.parse(suppliersDataUnparsed);
//     }
//   } catch (error) {
//     console.error(
//       "Error parsing data from local storage. Error details: ",
//       error
//     );
//     return;
//   }
//   if (!suppliersData) {
//     // If there's no data yet, init. This will always be auth data, because its addded before the service data
//     // ie: the user logs in before fetching their services
//     suppliersData = [newData as authData];
//   } else {
//     // Check if user already exists
//     const supplierIndex = suppliersData.findIndex(
//       (u) => u.supplierId === newData.supplierId
//     );
//     if (supplierIndex !== -1) {
//       // user exists, so we update its data
//       suppliersData[supplierIndex] = {
//         ...suppliersData[supplierIndex],
//         ...newData,
//       };
//     } else {
//       // user does not exist, so we add it. This will always be auth data, because its addded before the service data
//       // ie: the user logs in before fetching their services
//       suppliersData.push(newData as authData);
//     }
//   }
//   try {
//     // Update auth data in LS
//     localStorage.setItem(LS_KEYNAME, JSON.stringify(suppliersData));
//   } catch (error) {
//     console.error("Error updating auth data. Error details: ", error);
//   }
// }

export function existsUserDataLS(codeUsername: string): boolean {
  console.log("existsUserDataLS - BEGIN", codeUsername);
  try {
    // Get auth data from LS
    const currentData = localStorage.getItem(LS_KEYNAME);
    if (currentData) {
      const userData: LSData = JSON.parse(currentData);
      if (userData.some((ad) => ad.codeUsername === codeUsername)) {
        console.log("existsUserDataLS - END - true");
        return true;
      }
    }
    console.log("existsUserDataLS - END - false");
    return false;
  } catch (error) {
    console.error(
      "Error parsing data from local storage. Error details: ",
      error
    );
    return false;
  }
}

export function getTokenFromUserDataLS(codeUsername: string): string | null {
  console.log("getTokenFromUserDataLS - BEGIN", codeUsername);
  try {
    // Get auth data from LS
    const currentData = localStorage.getItem(LS_KEYNAME);
    if (currentData) {
      const userData: LSData = JSON.parse(currentData);
      const userDataIndex = userData.findIndex(
        (ad) => ad.codeUsername === codeUsername
      );
      if (userDataIndex !== -1) {
        console.log(
          "getTokenFromUserDataLS - END",
          userData[userDataIndex].token
        );
        return userData[userDataIndex].token;
      }
      console.log("getTokenFromUserDataLS - END null");
      return null;
    }
    console.log("getTokenFromUserDataLS - END null");
    return null;
  } catch (error) {
    console.error(
      "Error parsing data from local storage. Error details: ",
      error
    );
    return null;
  }
}
