import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import * as Sentry from "@sentry/react";
import logger from "redux-logger";
import rootReducer, { RootState } from "./rootReducer";

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

const retry = (_action: any, retries: any) => {
  return null;
};

// Don't hydrate user and service state from LS, because this is done os login.
// We only need to recover the offline actions that are pending
const config = {
  ...offlineConfig,
  retry,
  persistOptions: {
    whitelist: ["offline"],
  },
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), logger] as const,
  devTools: process.env.NODE_ENV !== "production",
  // devTools: true,
  // @ts-ignore
  enhancers: [offline(config), sentryReduxEnhancer],
});
