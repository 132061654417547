import styled from "../../theme";

export const Wrapper = styled.div`
  width: 70%;
  min-width: 370px;
  max-width: 800px;
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: ${(props) => props.theme.colors.background};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Footer = styled.div`
  margin-top: 1rem;
`;

export const HeaderText = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  text-align: center;
  flex-basis: 100%;
  font-size: 2.5rem;
  margin: 0;
`;

export const SubHeaderText = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  flex-basis: 100%;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const FooterText = styled.p`
  color: #1c1f2a;
`;
