import React from "react";
import { KeyboardKey, KeyboardGrid } from "../UI/Keyboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";

interface KeyboardProps {
  onClickHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickCaptureHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface KeyProps extends KeyboardProps {
  msg?: string;
  keyContent: number | string;
  fontSize?: string;
}

const ScreenKeyboardKey: React.FC<KeyProps> = ({
  msg,
  keyContent,
  onClickHandler,
  onClickCaptureHandler,
  fontSize,
  children,
}) => {
  return (
    <KeyboardKey
      onClick={onClickHandler}
      onClickCapture={onClickCaptureHandler}
      data-val={keyContent}
      fontSize={fontSize}
    >
      {children ?? msg ?? keyContent}
    </KeyboardKey>
  );
};

const ScreenKeyboard: React.FC<KeyboardProps> = ({ onClickHandler }) => {
  return (
    <KeyboardGrid>
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="1" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="2" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="3" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="4" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="5" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="6" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="7" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="8" />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="9" />
      <ScreenKeyboardKey
        onClickHandler={onClickHandler}
        keyContent="-2"
        msg="RESET"
        fontSize="1.5rem"
      />
      <ScreenKeyboardKey onClickHandler={onClickHandler} keyContent="0" />
      <ScreenKeyboardKey
        onClickCaptureHandler={onClickHandler}
        keyContent="-1"
        fontSize="1rem"
      >
        <FontAwesomeIcon icon={faBackspace} size="lg" />
      </ScreenKeyboardKey>
    </KeyboardGrid>
  );
};

export default ScreenKeyboard;
