import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "../../theme";
import Logo from "../../components/Logo/Logo";
import { Wrapper } from "../../components/UI/Card";
import {
  InputLabelGroup,
  Label,
  InputWithIcon,
  Form,
} from "../../components/UI/Form";
import { Button } from "../../components/UI/Button";
import usernameIcon from "../../components/UI/icon-username.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
`;

const AnswerRow = styled.div`
  margin: 1rem 0;
  > input {
    margin-right: 1rem;
  }
`;

const Title = styled.h4`
  flex-basis: 100%;
`;

const ButtonWrapper = styled.div`
  border-radius: 50px;
  border-top-left-radius: 0;
  width: 100px;
  height: 100px;
  background-color: #a20505;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const LinkMod2 = styled.span`
  color: white;
  text-align: center;
  text-decoration: none;
  margin-left: 0.3rem;
`;

export default function Support() {
  const history = useHistory();
  const [question, setQuestion] = useState("");

  function returnToPreviousPage() {
    history.goBack();
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Selected question", question);
    history.push("/support/success");
  };

  return (
    <>
      <Logo />
      <Wrapper>
        <Form onSubmit={handleSubmit}>
          <InputLabelGroup width={100}>
            <Label htmlFor="username">Prestador</Label>
            <InputWithIcon
              icon={usernameIcon}
              id="username"
              type="text"
              readOnly
              placeholder="Jane Doe"
            ></InputWithIcon>
          </InputLabelGroup>
          <Title>Porque precisa da ajuda da equipa de suporte ?</Title>
          <Fieldset>
            <legend>Selecione uma opção:</legend>
            <AnswerRow>
              <input
                type="radio"
                id="support_question_1"
                value="support_question_1"
                name="support_questions"
                onChange={handleChange}
                required
              />
              <label htmlFor="support_question_1">
                Preciso de tirar um dia de férias
              </label>
            </AnswerRow>
            <AnswerRow>
              <input
                type="radio"
                id="support_question_2"
                value="support_question_2"
                name="support_questions"
                onChange={handleChange}
              />
              <label htmlFor="support_question_2">
                Tenho uma justificação de faltas
              </label>
            </AnswerRow>
            <AnswerRow>
              <input
                type="radio"
                id="support_question_3"
                value="support_question_3"
                name="support_questions"
                onChange={handleChange}
              />
              <label htmlFor="support_question_3">Preciso de material</label>
            </AnswerRow>
            <AnswerRow>
              <input
                type="radio"
                id="support_question_4"
                value="support_question_4"
                name="support_questions"
                onChange={handleChange}
              />
              <label htmlFor="support_question_4">
                O aspirador deixou de funcionar
              </label>
            </AnswerRow>
            <AnswerRow>
              <input
                type="radio"
                id="support_question_5"
                value="support_question_5"
                name="support_questions"
                onChange={handleChange}
              />
              <label htmlFor="support_question_5">
                Preciso de usufruir da minha folga
              </label>
            </AnswerRow>
          </Fieldset>
          <Button marginTop="2rem" marginBottom="2rem">
            Pedir Ajuda
          </Button>
        </Form>
        <ButtonWrapper>
          <FontAwesomeIcon icon={faTimes} color="white" />
          <LinkMod2 onClick={returnToPreviousPage}>Fechar</LinkMod2>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
}
