import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "../../theme";
import { useLocation, Link } from "react-router-dom";

const Wrapper = styled.div`
  border-radius: 50px;
  border-top-right-radius: 0;
  width: 100px;
  height: 100px;
  background-color: #a20505;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const LinkMod = styled(Link)`
  color: white;
  text-align: center;
  text-decoration: none;
`;

export const Help = () => {
  let location = useLocation();
  return (
    <Wrapper>
      <LinkMod
        key={"foo"}
        to={{
          pathname: "/support",
          // This is the trick! This link sets the `background` in location state.
          state: { background: location },
        }}
      >
        Precisa de ajuda <FontAwesomeIcon icon={faQuestionCircle} />
      </LinkMod>
    </Wrapper>
  );
};
