import styled from "../../theme";

interface IInputLabelGroupProps {
  width?: number;
}

export const InputLabelGroup = styled.div<IInputLabelGroupProps>`
  flex-basis: ${(props) => (props.width ? `${props.width}%` : "100%")};
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  display: block;
`;

// interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
interface IInputProps {
  shouldFocus?: boolean;
  width?: number;
}

export const Input = styled.input<IInputProps>`
  /* iOS center text vertically hack */
  line-height: normal;
  box-sizing: border-box;
  width: 100%;
  box-shadow: ${(props) =>
    props.shouldFocus ? "0 2px 3px 0 #195cad, 0 -2px 3px 0 #195cad" : "none"};
  border: ${(props) => `1px solid ${props.theme.colors.input.border}`};
  border-radius: 6px;
  padding: 10px 10px;
  display: block;
  margin-right: 10px;
  font-size: 0.9rem;
  :focus {
    box-shadow: 0 2px 4px 0 #195cad, 0 -2px 4px 0 #195cad;
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.input.placeholder};
    font-size: 0.8rem;
  }
`;

export const Select = styled.select<IInputProps>`
  /* iOS center text vertically hack */
  line-height: normal;
  box-sizing: border-box;
  width: 100%;
  box-shadow: ${(props) =>
    props.shouldFocus ? "0 2px 3px 0 #195cad, 0 -2px 3px 0 #195cad" : "none"};
  border: ${(props) => `1px solid ${props.theme.colors.input.border}`};
  border-radius: 6px;
  padding: 10px 10px;
  display: block;
  font-size: 0.9rem;
  :focus {
    box-shadow: 0 2px 4px 0 #195cad, 0 -2px 4px 0 #195cad;
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.input.placeholder};
    font-size: 0.8rem;
  }
`;

interface IInputWithIconProps {
  icon: string;
}

export const InputWithIcon = styled(Input)<IInputWithIconProps>`
  background: url(${(props) => props.icon}) no-repeat scroll right 10px center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const InputGroupInlineWrapper = styled.div`
  display: flex;
`;
